<template>
  <ul
    v-if="availableHosts && availableHosts.length && hostId"
    class="clebex-item-section overflow-hidden"
  >
    <li
      class="clebex-item-section-item semicollapsed"
      :class="collapse['users'] ? 'expanded' : 'collapsed'"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label larger-label">
            {{ displayLabelName("bookings.bookings.host") }}
          </label>
        </div>
        <span class="follow-up-icons">
          <section v-if="search && collapse['users']">
            <search-component
              ref="search"
              @inputChanged="inputChanged"
              @lostFocus="search = false"
              @clearSearch="searchQuery = ''"
            ></search-component>
          </section>
          <span
            v-if="collapse['users']"
            @click="startSearch()"
            class="follow-up-icon-item cursor-pointer"
            ><icon icon="#cx-hea1-search" width="12" height="12"
          /></span>
          <span
            @click="collapse['users'] = !collapse['users']"
            class="follow-up-icon-item collapsable cursor-pointer"
            ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
          /></span>
        </span>
      </div>
    </li>
    <section
      ref="section"
      :class="collapse['users'] ? 'expanded five' : 'expanded one'"
    >
      <li
        v-for="user in listOfUsers.filter(el => el.id === hostId)"
        :key="user.id"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <div class="clebex-item-content-wrapper cursor-pointer">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer"
              >{{ user.first_name }} {{ user.last_name }}
            </label>
            <label
              v-if="collapse['users']"
              class="clebex-section-input-label cursor-pointer"
              >{{ user.email }}
            </label>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="12" height="12" style="width: 12px; height: 12px;">
                  <use xlink:href="#cx-app1-checkmark"></use>
                </svg>
              </div>
            </span>
          </span>
        </div>
      </li>
      <li
        v-for="user in listOfUsers.filter(el => el.id !== hostId)"
        :key="user.id"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <div
          class="clebex-item-content-wrapper cursor-pointer"
          @click="
            {
              scrollToView();
              selectUser(user.id);
            }
          "
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer"
              >{{ user.first_name }} {{ user.last_name }}
            </label>
            <label class="clebex-section-input-label cursor-pointer"
              >{{ user.email }}
            </label>
          </div>
        </div>
      </li>
    </section>
  </ul>
</template>

<script>
import SearchComponent from "@/components/search/SearchComponent";
import { mapState } from "vuex";

export default {
  name: "UsersSection",
  components: {
    SearchComponent
  },
  data() {
    return {
      collapse: {},
      search: false,
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("bookings", ["hostId", "availableHosts"]),
    listOfUsers() {
      const query = this.searchQuery;
      if (this.availableHosts && this.availableHosts.length) {
        if (query && query.length > 1) {
          return this.availableHosts.filter(item =>
            `${item.first_name} ${item.last_name} ${item.email}`
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        } else {
          return this.availableHosts;
        }
      }
      return null;
    }
  },
  methods: {
    selectUser(id) {
      this.search = false;
      this.searchQuery = "";
      this.$store.commit("bookings/setHostId", id, {
        root: true
      });
    },
    startSearch() {
      if (!this.search) {
        this.search = true;
        this.$nextTick(() => {
          this.$refs.search.$refs.searchComponent.focus();
        });
      } else {
        this.search = false;
      }
    },
    scrollToView() {
      this.$refs.section.scrollTop = 0;
    },
    inputChanged(val) {
      this.searchQuery = val;
    }
  }
};
</script>
